import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from "@angular/fire/auth-guard";
import { GnxGuard } from "./services/guards/gnx-token.guard";
import { OnboardingGuard } from "./services/guards/onboarding.guard";
import { GnxAuthGuard } from "./services/guards/gnx-auth.guard";
import { FirebaseGuard } from "./services/guards/firebase.guard";
const redirectUnauthorized = () => redirectUnauthorizedTo(["/login"]);
const redirectLoggedIn = () => redirectLoggedInTo(["/app/home"]);

const routes: Routes = [
  {
    path: "",
    redirectTo: "/onboarding",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/private/tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },

  {
    path: "onboarding",
    loadChildren: () =>
      import("../app/pages/public/onboarding/onboarding.module").then(
        (m) => m.OnboardingPageModule
      ),
    // canActivate: [],
    // canActivate: [OnboardingGuard],
  },
  {
    //@TODO Validar tipo de autenticacion
    path: "login",
    loadChildren: () =>
      import("./pages/public/login/login.module").then(
        (m) => m.LoginPageModule
      ),
    canActivate: [GnxAuthGuard],
    data: { authMethod: 'email' },
  },
  {
    //@TODO Validar tipo de autenticacion
    path: "otp",
    loadChildren: () =>
      import("./pages/public/otp/otp.module").then((m) => m.OtpPageModule),
    canActivate: [AngularFireAuthGuard, GnxAuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
  },

  {
    path: "profile",
    loadChildren: () =>
      import("./pages/private/profile/profile.module").then(
        (m) => m.ProfilePageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "orders",
    redirectTo: "/app/orders",
    pathMatch: "full",
  },
  {
    path: "order-detail",
    loadChildren: () =>
      import("./pages/private/order-detail/order-detail.module").then(
        (m) => m.OrderDetailPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "map",
    loadChildren: () =>
      import("./pages/private/map/map.module").then((m) => m.MapPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "login-chat",
    loadChildren: () =>
      import("./pages/public/login-chat/login-chat.module").then(
        (m) => m.LoginChatPageModule
      ),
  },

  {
    path: "auth-confirmation",
    loadChildren: () =>
      import("./pages/public/auth-confirmation/auth-confirmation.module").then(
        (m) => m.AuthConfirmationPageModule
      ),
  },
  {
    path: "take-picture",
    loadChildren: () =>
      import("./pages/public/take-picture/take-picture.module").then(
        (m) => m.TakePicturePageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "kpi-detail",
    loadChildren: () =>
      import("./pages/private/kpi-detail/kpi-detail.module").then(
        (m) => m.KpiDetailPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "change-email",
    loadChildren: () =>
      import("./pages/public/change-email/change-email.module").then(
        (m) => m.ChangeEmailPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "orders-success",
    loadChildren: () =>
      import("./pages/private/orders-success/orders-success.module").then(
        (m) => m.OrdersSuccessPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "page-terms-conditions",
    loadChildren: () =>
      import("./pages/private/page-terms-conditions/page-terms-conditions.module").then(
        (m) => m.PageTermsConditionsPageModule
      ),
  },
  {
    path: "privacy-policies",
    loadChildren: () =>
      import("./pages/private/privacy-policies/privacy-policies.module").then(
        (m) => m.PrivacyPoliciesPageModule
      ),
  },
  {
    path: "gamification-status",
    loadChildren: () =>
      import(
        "./pages/private/gamification-status/gamification-status.module"
      ).then((m) => m.GamificationStatusPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "gamification-details",
    loadChildren: () =>
      import(
        "./pages/private/gamification-details/gamification-details.module"
      ).then((m) => m.GamificationDetailsPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "catalogue/:id",
    loadChildren: () =>
      import("./pages/private/catalogue-detail/catalogue-detail.module").then(
        (m) => m.CatalogueDetailPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "forgot",
    loadChildren: () =>
      import("./pages/public/forgot/forgot.module").then(
        (m) => m.ForgotPageModule
      ),
  },
  {
    path: "challenges",
    loadChildren: () =>
      import("./pages/private/challenges/challenges.module").then(
        (m) => m.ChallengesPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "exhibitions",
    loadChildren: () =>
      import("./pages/private/exhibitions/exhibitions.module").then(
        (m) => m.ExhibitionsPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "exhibitions/form",
    loadChildren: () =>
      import("./pages/private/exhibition-form/exhibition-form.module").then(
        (m) => m.ExhibitionFormPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "exhibitions/detail/:id",
    loadChildren: () =>
      import("./pages/private/exhibition-detail/exhibition-detail.module").then(
        (m) => m.ExhibitionDetailPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "challenges/form/:type/:id",
    loadChildren: () =>
      import("./pages/private/challenges-form/challenges-form.module").then(
        (m) => m.ChallengesFormPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "challenges/detail/:type/:id",
    loadChildren: () =>
      import("./pages/private/challenges-detail/challenges-detail.module").then(
        (m) => m.ChallengesDetailPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "social-user-profile/:path/:id",
    loadChildren: () =>
      import(
        "./pages/private/social-user-profile/social-user-profile.module"
      ).then((m) => m.SocialUserProfilePageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "social-notifications/:path",
    loadChildren: () =>
      import(
        "./pages/private/social-notifications/social-notifications.module"
      ).then((m) => m.SocialNotificationsPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "social-post-by-hashtag",
    loadChildren: () =>
      import(
        "./pages/private/social-post-by-hashtag/social-post-by-hashtag.module"
      ).then((m) => m.SocialPostByHashtagPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("../app/pages/private/checkout/checkout.module").then(
        (m) => m.CheckoutPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "notifications",
    loadChildren: () =>
      import(
        "../app/pages/private/notifications-channels/notifications-channels.module"
      ).then((m) => m.NotificationsChannelsPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "social-birthday",
    loadChildren: () =>
      import("./pages/private/social-birthday/social-birthday.module").then(
        (m) => m.SocialBirthdayPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'nequi',
    loadChildren: () => import('./pages/private/nequi/nequi.module').then(m => m.NequiPageModule),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "change-phone",
    loadChildren: () =>
      import("./pages/private/change-phone/change-phone.module").then(
        (m) => m.ChangePhonePageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: "change-phone-otp",
    loadChildren: () =>
      import("./pages/private/change-phone-otp/change-phone-otp.module").then(
        (m) => m.ChangePhoneOtpPageModule
      ),
    canActivate: [AngularFireAuthGuard, GnxGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'invoices',
    loadChildren: () => import('./pages/private/invoices/invoices.module').then(m => m.InvoicesPageModule)
  },
  {
    path: 'invoice/detail/:id',
    loadChildren: () => import('./pages/private/invoice-detail/invoice-detail.module').then(m => m.InvoiceDetailPageModule)
  },
  {
    path: 'invoice/form',
    loadChildren: () => import('./pages/private/invoice-form/invoice-form.module').then(m => m.InvoiceFormPageModule)
  },
  {
    path: 'invoice/request-received/:id',
    loadChildren: () => import('./pages/private/invoices/received-request/received-request.module').then(m => m.ReceivedRequestPageModule)
  },
  {
    path: 'total-pass',
    loadChildren: () => import('./pages/private/total-pass/total-pass.module').then(m => m.TotalPassPageModule)
  },
  {
    path: 'delete-account-term',
    loadChildren: () => import('./pages/private/delete-account/delete-account-term/delete-account-term.module').then(m => m.DeleteAccountTermPageModule)
  },
  {
    path: 'delete-account-confirmation',
    loadChildren: () => import('./pages/private/delete-account/delete-account-confirmation/delete-account-confirmation.module').then(m => m.DeleteAccountConfirmationPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/public/register/register.module').then(m => m.RegisterPageModule),
    canActivate: [GnxAuthGuard]
  },
  {
    path: 'register-error',
    loadChildren: () => import('./pages/public/register-error/register-error.module').then(m => m.RegisterErrorPageModule)
  },
  {
    path: 'recover-account',
    loadChildren: () => import('./pages/public/recover-account/recover-account.module').then(m => m.RecoverAccountPageModule)
  },
  {
    path: 'confirm-auth',
    loadChildren: () => import('./pages/public/confirm-auth/confirm-auth.module').then(m => m.ConfirmAuthPageModule)
  },
  {
    path: 'new-password',
    loadChildren: () => import('./pages/private/new-password/new-password.module').then(m => m.NewPasswordPageModule)
  },
  {
    path: 'register-email',
    loadChildren: () => import('./pages/public/register-email/register-email.module').then(m => m.RegisterEmailPageModule)
  },
  {
    path: 'verify-code',
    loadChildren: () => import('./pages/public/verify-code/verify-code.module').then(m => m.VerifyCodePageModule),
    canActivate: [FirebaseGuard]
  },
  {
    path: 'recover-method',
    loadChildren: () => import('./pages/public/recover-method/recover-method.module').then(m => m.RecoverMethodPageModule)
  },
  {
    path: 'live-chat',
    loadChildren: () => import('./pages/private/live-chat/live-chat.module').then(m => m.LiveChatPageModule)
  },
  {
    path: 'recognitions-form',
    loadChildren: () => import('./pages/private/recognitions-form/recognitions-form.module').then(m => m.RecognitionsFormPageModule)
  },
  {
    path: 'recognitions',
    loadChildren: () => import('./pages/private/recognitions/recognitions.module').then(m => m.RecognitionsPageModule)
  },
  {
    path: 'recognitions-success',
    loadChildren: () => import('./pages/private/recognitions-success/recognitions-success.module').then(m => m.RecognitionsSuccessPageModule)
  },
  {
    path: 'exhibition-success',
    loadChildren: () => import('./pages/private/exhibition-success/exhibition-success.module').then(m => m.ExhibitionSuccessPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./pages/private/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartPageModule)
  },
  {
    path: 'confirm-checkout',
    loadChildren: () => import('./pages/private/confirm-checkout/confirm-checkout.module').then(m => m.ConfirmCheckoutPageModule)
  },
  {
    path: 'payment-services',
    loadChildren: () => import('./pages/private/payment-services/payment-services.module').then(m => m.PaymentServicesPageModule)
  },
  {
    path: 'payment-services/requestReceived/:id/:status',
    loadChildren: () => import('./pages/private/payment-services/request-received/request-received.module').then(m => m.RequestReceivedPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
