import { HasExternalPays } from './../../pages/private/catalogue/config-external-pays';
import { IPaymentServices } from './../../pages/private/payment-services/IPayment-services.model';
import { Injectable } from "@angular/core";
import {
  AngularFireRemoteConfig,
  filterFresh,
  scanToObject,
} from "@angular/fire/remote-config";
import { Observable, Subject, Subscription } from "rxjs";
import { first, map } from "rxjs/operators";
//Interfaces
import { ILang } from "../../interfaces/ILangs";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class RemoteConfigService {
  config: firebase.remoteConfig.RemoteConfig;
  //private textsIU: ILang;
  //Define language for default
  //languageDefault: string = 'spanish';
  textsIUSubject = new Subject<string>();
  constructor(private remoteConfig: AngularFireRemoteConfig) {
    remoteConfig.changes.pipe(
      filterFresh(172_800_000), // ensure we have values from at least 48 hours ago
      first(),
      // scanToObject when used this way is similar to defaults
      // but most importantly smart-casts remote config values and adds type safety
      scanToObject({
        // default options
        // TABS: 'hola'
      })
    );
    this.initRemoteConfig();
  }

  async initRemoteConfig() {
    this.getTabs();
    this.getLanguage("es");
  }
  getLanguage(language: string): Observable<ILang> {
    let languageSelected: any;
    switch (language) {
      case "es":
        languageSelected = this.remoteConfig.strings.SPANISH;
        break;
      case "en":
        languageSelected = this.remoteConfig.strings.ENGLISH;
        break;
      default:
        languageSelected = this.remoteConfig.strings.SPANISH;
        break;
    }

    return this.createObserver(languageSelected);
  }

  private createObserver(obs): Observable<any> {
    const obs$ = new Observable((observer) => {
      obs.subscribe(
        (response: any) => {
          if (response) {
            observer.next(JSON.parse(response));
            observer.complete();
          }
        },
        (err) => {
          observer.error(err);
        }
      );
    });
    return obs$;
  }

  getTabs() {
    return this.remoteConfig.strings.TABS;
  }
  // getHomePreview() {
  //   return this.remoteConfig.strings.HOME_PREVIEW;
  // }

  getSettingsMenu() {
    return this.createObserver(this.remoteConfig.strings.MENU);
  }
  getSettingsMenuWeb() {
    return this.createObserver(this.remoteConfig.strings.MENU_WEB);
  }
  getRoutes() {
    return this.createObserver(this.remoteConfig.strings.ROUTES);
  }
  getOnBoarding() {
    return this.createObserver(this.remoteConfig.strings.ONBOARDING);
  }
  getSettingsPageHome() {
    return this.createObserver(this.remoteConfig.strings.HOME);
  }
  getSetingsTabs() {
    return this.createObserver(this.remoteConfig.strings.TABS);
  }
  getSettingsAuthMethods() {
    return this.createObserver(this.remoteConfig.strings.AUTH_METHODS);
  }
  private getEnableReedem() {
    return this.createObserver(this.remoteConfig.strings.ENABLE_REEDEM);
  }
  authEmailIsActivated() {
    let method: boolean;
    this.getSettingsAuthMethods().subscribe((methods: any) => method = methods.email).unsubscribe()
    return method;
  }
  authPhoneIsActivated() {
    let method: boolean;
    this.getSettingsAuthMethods().subscribe((methods: any) => method = methods.phone).unsubscribe()
    return method;
  }
  authGoogleIsActivated() {
    let method: boolean;
    this.getSettingsAuthMethods().subscribe((methods: any) => method = methods.google).unsubscribe()
    return method;
  }
  enableReedem() {
    let enableReedem: boolean;
    this.getEnableReedem().subscribe((response: boolean) => enableReedem = response).unsubscribe()
    return enableReedem;
  }

  getVersionApp() {
    return this.createObserver(this.remoteConfig.strings.VERSION_APP);
  }
  getLoginChat() {
    return this.createObserver(this.remoteConfig.strings.LOGIN_CHAT);
  }
  getSettingsCategoryContent() {
    return this.createObserver(this.remoteConfig.strings.CATEGORY_CONTENT);
  }
  getInfoApp() {
    return this.createObserver(this.remoteConfig.strings.INFO);
  }
  getPhoneContact() {
    let phoneContact: string;
    this.getInfoApp().subscribe((response: any) => phoneContact = response['phone-contact']).unsubscribe()
    return phoneContact;
  }
  getPhoneIndicators() {
    let phoneIndicators: [];
    this.getInfoApp()
      .subscribe(
        (response: any) => (phoneIndicators = response["phone-indicators"])
      )
      .unsubscribe();
    return phoneIndicators;
  }
  getUnifiedQuestions() {
    return this.createObserver(this.remoteConfig.strings.UNIFIED_QUESTIONS);
  }
  getMultiplesAccounts() {
    return this.createObserver(this.remoteConfig.strings.MULTIPLES_ACCOUNTS);
  }
  getSettingsGeochallengesConfig() {
    return this.createObserver(this.remoteConfig.strings.GEOCHALLENGES);
  }
  getSettingsEmblueConfig() {
    return this.createObserver(this.remoteConfig.strings.EMBLUE);
  }
  getSettingsExhibitionsConfig() {
    return this.createObserver(this.remoteConfig.strings.EXHIBITIONS);
  }
  getHideGamification() {
    return this.createObserver(this.remoteConfig.strings.HIDE_GAMIFICATION);
  }
  getSettinContact() {
    return this.createObserver(this.remoteConfig.strings.CONTACT);
  }
  getSettingsInvoicesConfig() {
    return this.createObserver(this.remoteConfig.strings.INVOICES);
  }
  getKeySite() {
    return this.createObserver(this.remoteConfig.strings.SITE_KEY);
  }
  getEnableForm() {
    return this.createObserver(this.remoteConfig.strings.ENABLE_FORM_TOTAL);
  }
  getImages() {
    return this.createObserver(this.remoteConfig.strings.IMAGES);
  }
  getSettingPaymentServicesConfig(): Observable<IPaymentServices> {
    return this.createObserver(this.remoteConfig.strings.PAYMENT_SERVICES);
  }
  getSettingConfigExternalPay(): Observable<HasExternalPays> {
    return this.createObserver(this.remoteConfig.strings.CONFIG_EXTERNAL_PAY);
  }
}
