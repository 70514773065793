import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "../storage/storage.service";
import { AuthService } from "../auth/auth.service";
import { Platform } from "@ionic/angular";
import { UtilsService } from "src/app/services/utils/utils.service";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class GnxGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private platform: Platform,
    private utilsService: UtilsService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean> {
    return this.storageService.get("gnx-token").then(async (res) => {
      if (res) {
        var user = this.parseJwt(res);
        //console.log("user", user);
        if (user && user['iat']) {
          var minutesToAdd = 35;
          const expiryTime = new Date(user['iat'] * 1000);
          var futureDate = new Date(expiryTime.getTime() + minutesToAdd * 60000);
          var currentDate = new Date();
          //console.log("expiryTime", expiryTime, new Date(expiryTime))
          //console.log("futureDate", futureDate, new Date(futureDate))
          if (this.platform.is("desktop") && currentDate.valueOf() > futureDate.valueOf()) {
            await this.storageService.removeAll();
            await this.authService.logout();
            return false;
          }
        }
        return true;
      }
      await this.storageService.removeAll();
      await this.authService.logout();
      return false;
    });
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
}
