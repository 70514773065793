import { Component, Input, OnInit } from '@angular/core';
import { RouterService } from 'src/app/services/router/router.service';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.scss'],
})
export class HeaderLoginComponent implements OnInit {

  @Input() title: string;
  @Input() page: string; 
  
  constructor(private routerService: RouterService) { }

  ngOnInit() {}

  goBack(page){
    this.routerService.navigate(page)
  }

}
