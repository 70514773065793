// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appName: "Kimberly Kracs Chile",
  gmApiKey: "AIzaSyD7QFaIRrHg6neyAToyVDUM6mkJSSHzo_c", //INFO GPS > Api y Servicios > Credenciales > Browser Key
  firebaseConfig: { //INFO Configuracion de firebase de cada proyecto
    apiKey: "AIzaSyD7QFaIRrHg6neyAToyVDUM6mkJSSHzo_c",
    authDomain: "gnx-kimberly-kracs-cl-prd.firebaseapp.com",
    projectId: "gnx-kimberly-kracs-cl-prd",
    storageBucket: "gnx-kimberly-kracs-cl-prd.appspot.com",
    messagingSenderId: "825358480120",
    appId: "1:825358480120:web:40942962b72be859a2c10d",
    measurementId: "G-QL7LRV7B41"
  },
  gluky: {
    app_id: "gnx_kimberly_kracks_cl_prd", //INFO Admin Gluky > Usuario > Aplicacion
    programId: "kimberly_clark_chile",
    companyId: "kimberly_clark_chile", //INFO Admin Gluky > Usuario > Empresas
    apikey: "AIzaSyD7QFaIRrHg6neyAToyVDUM6mkJSSHzo_c", //INFO GPS > Api y Servicios > Credenciales > Browser Key
    apikeyPwa: "AIzaSyCIPdOt7GYLd-w2KxOga8t3bsT_UT7uquw", //INFO GPS > Api y Servicios > Credenciales > PWA Key
    apiKeyWeb: "AIzaSyD7QFaIRrHg6neyAToyVDUM6mkJSSHzo_c", //INFO GPS > Api y Servicios > Credenciales > Browser Key
    apiKeyAndroid: "AIzaSyAeXX10kUK2RiZX9g3x3qz3mboARYsSxdU", //INFO GPS > Api y Servicios > Credenciales > Android Key
    usersApiBasePath: 'https://users-api.glukynet.com/v2',
    ordersApiBasePatch: 'https://orders-api.glukynet.com/v1',
    pointsApiBasePath: 'https://bank-api.glukynet.com/v1',
    bffApiBasePath: "https://bff-api.cms.glukynet.comm/v1",
    bffApiBasePathV2: "https://bffv2-cms.glukynet.com/v2",
    productsApiBasePath: 'https://products-front-api.glukynet.com/v1',
    catalogueApiBasePath: 'https://fb07883f71224ce39a631c312f9e280b.ent-search.us-east1.gcp.elastic-cloud.com',
    challengesStrapiApiBasePath: 'https://bff-api.cms.glukynet.com/v1/contents/challenges',
    productsUrl: 'https://products-front-api.glukynet.com/v1',
    recognitionsUrl: 'https://recognitions-front-us-east1-bryp80ke.ue.gateway.dev/v1',
    algoliaSearchUrl: 'https://instantsearch-us-east1-czsrv8lj.ue.gateway.dev/v1/front/simple/app/gnx_kimberly_kracks_cl_prd/index',
    kpiFrontApiBasePath: "https://kpis-front-us-east1-21cvo6vg.ue.gateway.dev/v1"
  },
  recaptcha: {
    siteKey: '6Ldcu5YlAAAAALQRm7Cu4hVkGjpBCA_ZJGPXf6Hb',
  },
  country: "cl",
  img_profile_default:
    "https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/defaults%2Fdefault-user.jpg?alt=media&token=15fbfa41-6ba2-4a88-8530-e5e5f41e45a4",
  phoneIndicator: "+56",
  clevertapAccountId: "4R6-87K-895Z",
  pathNotification: "https://notifications-api.glukynet.com/v1",
  dinamycLink: {
    url: "https://mvpimproved.page.link/C1mr",
    packageName: "com.gluky.mvpimproved",
  },
  geochallenges: {
    proximityRadiusInMeters: 800
  },
  emblu: {
    endpoint: "https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/getEmblueToken",
    associatePushToEmail: "https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/setAssociatePushToEmail",
    newContact: "https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/setNewContact"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
